import { Controller } from "@hotwired/stimulus"
import round from "lodash/round"
import forEach from "lodash/forEach"

export default class extends Controller {
  static targets = [
    "country",
    "button",
    "people",
    "litersPerPerson",
    "bottleSize",
    "moneyResult",
    "plasticResult",
    "carbonResult",
    "resourcesResult",
    "currency",
    "price",
    "results",
    "moneyResultDay",
    "moneyResultYear",
    "plasticResultDay",
    "plasticResultYear",
    "carbonResultDay",
    "carbonResultYear",
    "resourcesResultDay",
    "resourcesResultYear",
    "legendModal",
  ]

  setPrice(priceInUsd, xr) {
    const priceInCurrency = round(priceInUsd * xr, 2);
    this.priceTarget.value = priceInCurrency;
    this.data.set("priceInUsd", priceInUsd);
  }

  onCountryChange(e) {
    const countryOption = e.target.selectedOptions[0];
    const currencyOption = Array.prototype.find.call(this.currencyTarget.options, (option) => {
      return option.value.split(",").includes(countryOption.value);
    });

    this.setPrice(countryOption.dataset.price, currencyOption.dataset.xr);
    currencyOption.selected = true;
    this.bottleSizeTarget.value = "1.5";
  }

  onCurrencyChange(e) {
    const priceInDollars = parseFloat(this.data.get("priceInUsd"));
    const currencyOption = e.target.selectedOptions[0];
    this.setPrice(priceInDollars, currencyOption.dataset.xr);
  }

  onPriceChange(e) {
    const currencyOption = this.currencyTarget.selectedOptions[0];
    const priceInUsd = round(parseFloat(e.target.value) / currencyOption.dataset.xr, 2);
    this.data.set("priceInUsd", priceInUsd);
  }

  toggleLegend() {
    this.legendModalTarget.classList.toggle("is-active");
  }

  submit(e) {
    e.preventDefault();
    this.resultsTarget.scrollIntoView();
    [
      this.moneyResultTarget,
      this.plasticResultTarget,
      this.carbonResultTarget,
      this.resourcesResultTarget
    ].forEach((tableRow) => {
      this.toggleRowAnimation(tableRow);
    })
    this.calculate();

    return false;
  }

  calculate() {
    setTimeout(() => {
      this.calculateMoney();
      this.toggleRowAnimation(this.moneyResultTarget);
      setTimeout(() => {
        this.calculatePlastic();
        this.toggleRowAnimation(this.plasticResultTarget);
        setTimeout(() => {
          this.calculateCarbon();
          this.toggleRowAnimation(this.carbonResultTarget);
          setTimeout(() => {
            this.calculateResources();
            this.toggleRowAnimation(this.resourcesResultTarget);
          }, this.randomCalculationTime());
        }, this.randomCalculationTime());
      }, this.randomCalculationTime());
    }, this.randomCalculationTime());
  }

  calculateMoney() {
    const pricePerLiters = this.priceTarget.value / this.bottleSizeTarget.value;
    const moneySaved = pricePerLiters * this.totalLitersConsumed();
    const currencySymbol = this.currencyTarget.selectedOptions[0].dataset.symbol;
    this.moneyResultDayTarget.innerText = `${round(moneySaved, 2)} ${currencySymbol}`;
    this.moneyResultYearTarget.innerText = `${round(moneySaved * 365, 2)} ${currencySymbol}`;
  }

  calculatePlastic() {
    const weightPerLiter = 0.02173;
    const totalWeight = weightPerLiter * this.totalLitersConsumed();
    this.plasticResultDayTarget.innerText = `${round(totalWeight, 2)} kg`;
    this.plasticResultYearTarget.innerText = `${round(totalWeight * 365, 2)} kg`;
  }

  calculateCarbon() {
    const carbonPerLiter = 82.8 * 2;
    // We only know the carboon footprint of a 0.5L bottle, but this can't be extrapolated 1:1 to larger bottles
    // 1 - this.bottleSizeTarget.value / 10 -> Decent approximation to discount for larger bottles
    // i.e. would result in 15% reduction for an 1.5L bottle
    // + 0.05 -> No discount for 0.5L bottles... i.e. would result in total discount of 10% for a 1.5L bottle
    // It seems unreasonable to discount more than 50%
    const discountBottleSize = Math.max((1 - this.bottleSizeTarget.value / 10 + 0.05), 0.5);
    const totalCarbon = carbonPerLiter * this.totalLitersConsumed() * discountBottleSize / 1000;
    this.carbonResultDayTarget.innerText = `${round(totalCarbon, 2)} kg`;
    this.carbonResultYearTarget.innerText = `${round(totalCarbon * 365, 2)} kg`;
  }

  calculateResources() {
    this.resourcesResultDayTarget.innerText = `${round(1.47 * this.totalLitersConsumed() - this.totalLitersConsumed(), 2)} liters`;
    this.resourcesResultYearTarget.innerText = `${round((1.47 * this.totalLitersConsumed() - this.totalLitersConsumed()) * 365, 2)} liters`;
  }

  totalLitersConsumed() {
    return this.peopleTarget.value * this.litersPerPersonTarget.value;
  }

  toggleRowAnimation(row) {
    forEach(row.cells, ((cell, i) => {
      if (i !== 0) {
        cell.classList.toggle("is-hidden");
      }
    }));
  }

  randomCalculationTime() {
    return 500 + Math.random() * 1000;
  }
}