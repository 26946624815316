import Awesomplete from "awesomplete"
import { Controller } from "@hotwired/stimulus"
import sortBy from "lodash/sortBy"
import { parameterize } from "../utils"

export default class extends Controller {
  static targets = ["field"]

  initialize() {
    this.countryData = this.parseCountries(window.countries);
    const countries = this.transformCountries(this.countryData);
    const cities = this.transformCities(window.cities);
    this.createAwesomplete(cities.concat(countries));
  }

  connect() {
    this.fieldTarget.addEventListener("awesomplete-selectcomplete", (e) => {
      this.fieldTarget.blur();
      this.fieldTarget.value = e.text.label;
      Turbolinks.visit(e.text.value);
    });
  }

  parseCountries = (countries) => {
    const parsedCountries = countries.map((country) => {
      const [name, emoji, alpha2] = country.split("%");
      return {
        name,
        emoji,
        alpha2
      };
    });
    return sortBy(parsedCountries, "name");
  }

  transformCities(cities) {
    return cities.map((city) => {
      const [name, alpha2] = city.split("%");
      const country = this.countryData.find((country) => country.alpha2 === alpha2 );
      return {
        label: `${name} (${country.name} ${country.emoji})`,
        value: `/${parameterize(country.name)}/${parameterize(name)}`,
      };
    });
  }

  transformCountries(countries) {
    return countries.map((country) => {
      return {
        label: `${country.emoji} ${country.name} (${country.alpha2})`,
        value: `/${parameterize(country.name)}`,
      };
    });
  }

  createAwesomplete(list) {
    window.aw = new Awesomplete(this.fieldTarget, {
      list,
      maxItems: 5,
      minChars: 1,
      autoFirst: true,
    });
  }
}
