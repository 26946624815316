import { Controller } from "@hotwired/stimulus"
import * as L from 'leaflet';
import "leaflet/dist/leaflet.css";
import inRange from "lodash/inRange";
import { parameterize } from "../utils"

const DEFAULT_ZOOM_LEVEL = 3;
export default class extends Controller {
  static targets = [ "map" ]

  connect() {
    const o = L.latLng(-89.98155760646617, -180);
    const l = L.latLng(89.99346179538875, 180);
    const bounds = L.latLngBounds(o, l);
    this.map = L.map(this.mapTarget, {
      preferCanvas: true,
      attributionControl: false,
      center: {
        lat: 45,
        lng: 0
      },
      zoom: DEFAULT_ZOOM_LEVEL,
      minZoom: 2,
      maxZoom: 18,
      maxBounds: bounds,
      maxBoundsViscosity: 1.0
    });

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    L.control.attribution({
      position: 'bottomleft'
    }).addTo(this.map);

    this.map.createPane("labels");
    this.map.getPane("labels").style.zIndex = 650;
    this.map.getPane("labels").style.pointerEvents = "none";
    L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png", {
        pane: "labels"
    }).addTo(this.map);

    L.geoJson(
      window.geoCountries,
      {
        style: this.style.bind(this),
        onEachFeature: this.onEachFeature.bind(this)
      }
    ).addTo(this.map);

    var info = L.control({position: "bottomleft"});

    info.onAdd = function (map) {
        this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
        this._div.innerHTML = "<h1 class='is-6 subtitle'>Tap water quality score map ⛲️</h1><p>ℹ️ The data does not consider the taste</p>";
        return this._div;
    };

    info.addTo(this.map);
  }

  style(feature) {
    let fillColor;

    if (feature.properties.who === null && feature.properties.numbeo === null) {
      fillColor = "#fafafa";
    } else {
      let score = feature.properties.who;
      if (feature.properties.who !== null && feature.properties.numbeo !== null) {
        score = (feature.properties.who * 3 + feature.properties.numbeo) / 4;
      } else if (feature.properties.who === null && feature.properties.numbeo !== null) {
        score = feature.properties.numbeo;
      }
      fillColor = this.backgroundColor(score);
    }

    return {
      fillColor,
      fillOpacity: 0.95,
      stroke: true,
      color: "#ffffff",
      dashArray: "3",
      weight: 1
    };
  }

  backgroundColor(score) {
    if (inRange(score, 0, 61)) {
      return "#E7DFC6";
    } else if (inRange(score, 61, 71)) {
      return "#BBD3C8";
    } else if (inRange(score, 71, 81)) {
      return "#90C7C9";
    } else if (inRange(score, 81, 91)) {
      return "#64BACB";
    } else if (inRange(score, 91, 101)) {
      return "#38AECC";
    }
  }

  onEachFeature(feature, layer) {
    const popupHTML = `
      <h2 class="subtitle">${feature.properties.name} ${feature.properties.emoji}</h2>
      ${feature.properties.who === null ? "" : `
        <div class="tags has-addons">
          <span class="tag is-primary">WHO</span>
          <span class="tag">${feature.properties.who} / 100</span>
        </div>
      `}
      ${feature.properties.numbeo === null ? "" : `
        <div class="tags has-addons">
          <span class="tag is-primary">Numbeo</span>
          <span class="tag">${feature.properties.numbeo} / 100</span>
        </div>
      `}
      <a class="button is-info" href="/${parameterize(feature.properties.name).toLowerCase()}">Find out more</a>
    `;
    layer.bindPopup(popupHTML).on({
      'popupclose': this.closePopup.bind(this),
    });
    layer.on({
      mouseover: this.highlightFeature.bind(this),
      mouseout: this.resetHighlightFeature.bind(this),
      click: this.clickFeature.bind(this)
    })
  }

  highlightFeature(feature) {
    const element = feature.target;
    element.setStyle({
        fillOpacity: 1,
        color: "#ffffff",
        weight: 4,
        dashArray: ""
    });
    element.bringToFront();
  }

  resetHighlightFeature(event) {
    const target = event.target;
    if (target.feature.properties.active) { return; }
    target.setStyle(this.style(target.feature));
  }

  clickFeature(event) {
    event.target.feature.properties.active = true;
    this.highlightFeature(event);
  }

  closePopup(event) {
    event.target.feature.properties.active = false;
    this.resetHighlightFeature(event);
  }
}