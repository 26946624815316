import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "burger", "menu", "logo" ]

  ani() {
    this.logoTarget.classList.add("hovered");
    setTimeout(() => {
      this.logoTarget.classList.remove("hovered");
    }, 2000);
  }

  toggle() {
    this.burgerTarget.classList.toggle("is-active")
    this.menuTarget.classList.toggle("is-active")
  }
}