import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "other", "thankyou"]

  connect() {
    if (navigator.share) {
      this.otherTarget.classList.remove("is-hidden")
    }
  }

  open() {
    this.hideThankyou();
    this.modalTarget.classList.add('is-active');
    document.documentElement.classList.add('is-clipped');
  }

  close() {
    this.modalTarget.classList.remove('is-active');
    document.documentElement.classList.remove('is-clipped');
  }

  showTankyou() {
    this.thankyouTarget.classList.remove("is-hidden");
    setTimeout(() => {
      this.thankyouTarget.classList.add("is-hidden");
    }, 3000);
  }

  hideThankyou() {
    this.thankyouTarget.classList.add("is-hidden");
  }

  facebook() {
    this.openPopup(`https://www.facebook.com/sharer.php?u=${window.location.href}`);
    this.close();
  }

  twitter() {
    this.openPopup(`https://www.twitter.com/share?url=${window.location.href}`);
    this.close();
  }

  linkedin() {
    this.openPopup(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`);
    this.close();
  }

  reddit() {
    this.openPopup(`http://www.reddit.com/submit?url=${window.location.href}`);
    this.close();
  }

  email() {
    const mailto = `mailto:?subject=${document.title}&body=${this.metaDescription()}\n\n${window.location.href}`;
    this.openPopup(encodeURI(mailto));
    this.close();
  }

  other() {
    navigator.share({
      title: document.title,
      text: this.metaDescription(),
      url: window.location.href
    });
    this.close();
    this.showTankyou();
  }

  metaDescription() {
    return document.querySelector("meta[name='description']").content;
  }

  openPopup(url) {
    const newWindow = window.open(url, null, 'scrollbars=yes,width=560,height=340');

    // Puts focus on the newWindow
    if (window.focus) newWindow.focus();

    const timer = setInterval(() => {
        if(newWindow.closed) {
            clearInterval(timer);
            this.showTankyou();
        }
    }, 1000);
  }
}
